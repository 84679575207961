









import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import Region from '@/model/region'
import { DataTableHeader } from 'node_modules/vuetify/types';

@Component({
  components: {

  },
})
export default class Home extends Vue {

  @Getter getRegions: Region[]
  @Action fetchRegions

  private headers: DataTableHeader[] = [
    { text: 'ID', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Name (international)', value: 'name_int' },
  ];

  created(){
    this.fetchRegions();
  }
}
